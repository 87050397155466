import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import React from 'react'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import styled from 'styled-components'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'
import ButtonBlack from 'components/button/button-black'
import ContactForm from 'components/contact-form'
import CrackedTeeth from '../../../content/images/cracked tooth.gif'
import { Helmet } from 'react-helmet'
import OGImage from '../../../content/images/logo.png'

interface Props {
  location: Location
}

const CrackedTeethPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const hero_img = data.hero_img?.childImageSharp?.fluid
  const crack_img1 = data.crack_img1?.childImageSharp?.fluid
  const crack_img2 = data.crack_img2?.childImageSharp?.fluid
  const crack_img3 = data.crack_img3?.childImageSharp?.fluid

  return (
    <Layout location={location}>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
        <title>Cracked Teeth Indianapolis IN</title>
        <meta name="description" content="Endodontist Dr. Broady. Cracked teeth demonstrate many types of symptoms. Call our Indianapolis IN office at 317-846-4980 for more information." />
        <link rel="canonical" href="https://meridianendoindy.com/procedures/cracked-teeth/" />
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="Cracked Teeth Indianapolis IN" />
        <meta name="og:description" content="Endodontist Dr. Broady. Cracked teeth demonstrate many types of symptoms. Call our Indianapolis IN office at 317-846-4980 for more information." />
        <meta property="og:url" content="https://meridianendoindy.com/procedures/cracked-teeth/" />
        <meta property="og:image" content={OGImage} />
        <meta property="og:image:width" content="279" />
        <meta property="og:image:height" content="65" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Cracked Teeth Indianapolis IN" />
        <meta name="twitter:description" content="Endodontist Dr. Broady. Cracked teeth demonstrate many types of symptoms. Call our Indianapolis IN office at 317-846-4980 for more information." />
        <meta name="twitter:url" content="https://meridianendoindy.com/procedures/cracked-teeth/" />
        <meta name="twitter:image" content={OGImage} />
        <meta property="og:image:width" content="279" />
        <meta property="og:image:height" content="65" />
      </Helmet>
      <BackgroundImage
        Tag="section"
        className="cracked-teeth-hero-section-bg hero-bg"
        fluid={hero_background}
        backgroundColor={`#ffffff`}
        alt="Endodontics pc"
      >
        <div id="cracked-teeth-hero-section">
          <Container>
            <div className="col-lg-6 hero-left">
              <h1 className="hero-heading primary-text">
                Cracked Teeth
              </h1>
              <p className="hero-text">
                Cracked teeth demonstrate many types of symptoms, including pain when chewing, temperature sensitivities, or even the release of biting pressure.
              </p>
              <Link to="/contact-us/contact-information-office-map"><button className="hero-section-btn"> Schedule a Consultation</button></Link>
            </div>
            <div className="col-lg-6">
              <Img fluid={hero_img} className="hero-img"/>
            </div>
          </Container>
        </div>
      </BackgroundImage>
      <section id="faded-bg"></section>
      <section id="cracked-teeth-section-1">
        <Container>
            <div className="col-lg-12 cracked-teeth-container">
              <div className="row">
                <div className="col-lg-8">
                  <h4>Cracked Teeth</h4>
                  <p>
                    Cracked teeth can be bothersome for many reasons, including pain when chewing or temperature sensitivities. Unfortunately, it's common to temporarily feel these symptoms, which makes the discomfort challenging to diagnose.
                  </p>
                  <p>
                    When you chew, it can cause the cracked pieces of the tooth to move, irritating the pulp in the tooth. Additionally, the motion of eating causes pressure to build and release, resulting in sharp pain. Eventually, the pulp is damaged, and the tooth will hurt consistently. Another problem is the potential for an infection of the pulp tissue. The infection can spread to the bone and gum surrounding the tooth, too.
                  </p>
                </div>
                <div className="col-lg-4">
                  <img src={CrackedTeeth} />
                  {/*<iframe className="section1-video" src="https://fast.wistia.net/embed/iframe/i44svsk8il"></iframe>
                  <p className="text-center click-here"><a href="https://fast.wistia.net/embed/iframe/i44svsk8il" target="_blank">Click here for high-res version</a></p> */}
                </div>
              </div>
            </div>
        </Container>
      </section>

      <section id="cracked-teeth-section-2">
        <Container>
          <div className="col-lg-12">
            <h3>Types of Cracks</h3>
            <h4>Craze Lines</h4>
            <p>These are tiny cracks on the outside of the tooth that don't go past the outer enamel. They're more common in adults, are superficial, and are often not a reason for concern.</p>
            </div>
          <div className="row type-of-cracks">
            <div className="col-lg-3">
              <Img fluid={crack_img1} className="crack-img"/>
            </div>
            <div className="col-lg-9 crack-types-border">
              <p className="crack-title">Fractured Cusp</p>
              <p className="crack-content">
                Fractured cusps occur when the area weakens. It can break on its own or be removed by your dentist. These rarely damage the pulp, so a root canal isn't necessary for these cracks. Instead, a restoration dentist places a crown over the tooth.
              </p>
            </div>
          </div>
          <div className="row type-of-cracks">
            <div className="col-lg-3">
              <Img fluid={crack_img2} className="crack-img"/>
            </div>
            <div className="col-lg-9 crack-types-border">
              <p className="crack-title">Cracked Tooth</p>
              <p className="crack-content">
                A traditional cracked tooth goes from the chewing surface to the root. These can go into the root and damage the pulp. A root canal is necessary to fix this type of problem. If it's not repaired right away, the patient can lose their tooth.
              </p>
            </div>
          </div>
          <div className="row type-of-cracks">
            <div className="col-lg-3">
              <Img fluid={crack_img3} className="crack-img"/>
            </div>
            <div className="col-lg-9 crack-types-border">
              <p className="crack-title">Split Tooth</p>
              <p className="crack-content">
                When a tooth splits, it's often a result of a cracked tooth that wasn't treated. The crack is visible and extends through the tooth. The doctor can't save the whole tooth when there is a split. However, part of the tooth can still be saved with endodontic treatment. Patients must follow up with an immediate appointment with a restorative dentist to place a crown over the tooth to protect the root from further damage and infection.
              </p>
            </div>
          </div>
          <div className="row type-of-cracks">
            <div className="col-lg-3">
              <Img fluid={crack_img1} className="crack-img"/>
            </div>
            <div className="col-lg-9 crack-types-border crack-types-no-border">
              <p className="crack-title">Vertical Root Fracture</p>
              <p className="crack-content">
                A vertical root fracture is slightly different. It starts at the root and goes to the chewing surface. These are easy to miss because the crack rarely causes pain, and symptoms are often mild. Our endodontists can treat the tooth and perform endodontic surgery to save part of it. In many cases, depending on how extensive the damage is, the tooth will need to be removed.
              </p>
            </div>
            <div className="col-lg-12 text-center video-container">
              <p>To learn more about cracked tooth symptoms and treatments, watch the video below</p>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/oL6Do_AVAeA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <h5>For questions about cracked tooth repair or to schedule a consultation with the doctor, please call <a href="tel:3178464980">317-846-4980.</a></h5>
            </div>
          </div>
        </Container>
      </section>

      <section id="cracked-teeth-section-3">
        <ContactForm/>
      </section>
    </Layout>
  )
}

export default CrackedTeethPage

export const query = graphql`
  query CrackedTeethPageQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
      }
    },
    hero_background: file(name: { eq: "introduction-hero-bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_img: file(name: { eq: "cracked-teeth-hero-img" }) {
      childImageSharp {
        fluid(maxWidth: 520, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    crack_img1: file(name: { eq: "crack-img1" }) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    crack_img2: file(name: { eq: "crack-img" }) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    crack_img3: file(name: { eq: "split-tooth-img" }) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`
